/* Apply a global box-sizing rule */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Reset margin and padding */
body, html {
    margin: 0;
    padding: 0;
}

/* Background image with dark overlay */
body {
    background: url('https://firebasestorage.googleapis.com/v0/b/enigma-9da04.appspot.com/o/default.png?alt=media&token=2955f415-4356-40d4-a28e-f66f234fc199') no-repeat center center fixed;
    background-size: cover;
    position: relative;
    color: white;
    font-family: Arial, sans-serif;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

body::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: -1;
}

/* Main content container */
.content {
    z-index: 1;
    max-width: 600px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Header styling */
h1 {
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* Paragraph styling */
p {
    margin-bottom: 20px;
    line-height: 1.6;
}

/* Button styling */
button {
    background-color: #f39c12;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    outline: none; /* Ensure no outline on focus */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add shadow to buttons */
}

button:hover {
    background-color: #e67e22;
}

button:active {
    background-color: #d35400; /* Slightly darker shade on click */
}

input[type="text"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 10px;
    outline: none; /* Ensure no outline on focus */
}
